// extracted by mini-css-extract-plugin
export var chrome_intro = "index-module--chrome_intro--369d1";
export var flex_box = "index-module--flex_box--b98f1";
export var flex_group = "index-module--flex_group--69351";
export var flex_item = "index-module--flex_item--de31e";
export var img_container = "index-module--img_container--03938";
export var line = "index-module--line--004ee";
export var marketingForce = "index-module--marketingForce--8da72";
export var render_item = "index-module--render_item--478f0";
export var service = "index-module--service--70ebd";
export var service_title = "index-module--service_title--11504";
export var text_big = "index-module--text_big--44504";
export var text_container = "index-module--text_container--b946f";
export var text_intro = "index-module--text_intro--af8aa";
export var text_normal = "index-module--text_normal--42f9c";
export var text_title = "index-module--text_title--701e0";
export var title = "index-module--title--da5ba";