import React, { ReactNode } from "react";

import Helmet from "react-helmet"

import { Row, Col } from "antd"
import "antd/dist/antd.css"
import * as styles from "./index.module.scss";

import { Ipage } from "@src/pages"

interface Ititle {
    children: string | ReactNode
}
function Title({ children }: Ititle) {
    return <div className={styles.title}>{children}</div>

}

interface IchromIntro {
    img: any;
    intros: string[]
}
function ChromeIntro({ img, intros }: IchromIntro) {
    return <div className={styles.chrome_intro}>
        <div><img src={img} alt="img" /></div>
        <ul>
            {
                intros.map(item => <li key={item}>{item}</li>)
            }
        </ul>
    </div>
}
interface IrenderItem {
    img: any,
    text_title: string,
    text_intro: string,
}
function RenderItem({ img, text_title, text_intro }: IrenderItem) {
    return <div className={styles.render_item}>
        <div className={styles.img_container}>
            <img src={img} alt='this is a image' />
        </div>
        <div className={styles.text_container}>
            <div className={styles.text_title}>{text_title}</div>
            <div className={styles.text_intro}>{text_intro}</div>
        </div>
    </div>
}

function Line() {
    return <div className={styles.line}></div>
}

interface IflexBox {
    children: string | ReactNode[] | ReactNode
}
function FlexBox({ children }: IflexBox) {
    return <div className={styles.flex_box}>{children}</div>
}

function getImg(imageName: string) {
    let res = require(`@images/marketing_force/${imageName}.png`)
    return res.default
}
const QueueOne = [{
    img: 'itemBg1',
    text_title: "图片广告",
    text_intro: "精美图片呈现商品优点，简洁直观吸引视线"
}, {
    img: 'itemBg2',
    text_title: "视频广告",
    text_intro: "音画结合展示商品特色，多元信息集成更显震撼"
}, {
    img: 'itemBg3',
    text_title: "幻灯片广告",
    text_intro: "与视频广告相类，但更适应不同网速、不同设备"
}, {
    img: 'itemBg4',
    text_title: "轮播广告",
    text_intro: "单条放出10张图片/10段视频，可设专属链接，无死角凸显商品亮眼品质"
}, {
    img: 'itemBg5',
    text_title: "精品栏广告",
    text_intro: "主打广告封面/视频+商品小图组合，轻触进入深度体验，助兴趣化作购买欲"
}, {
    img: 'itemBg6',
    text_title: "快拍",
    text_intro: "5秒精致照片，15秒浓缩视频，短小精悍的沉浸式用户交互，低成本达成品牌提升"
}]

const QueueTwo = [{
    img: 'itemBg7',
    text_title: "搜索广告",
    text_intro: "搜索关键词匹配，结果页文字光速直传信息"
}, {
    img: 'itemBg8',
    text_title: "展示广告",
    text_intro: "图文生动结合，覆盖全球 90% 的互联网用户，知名网站/应用多形态展示"
}, {
    img: 'itemBg9',
    text_title: "视频广告",
    text_intro: "数秒视频，在 YouTube 上尽显风采"
},]

const QueueThree = [
    {
        img: 'itemBg10',
        text_title: "搜索广告",
        text_intro: "免费展示，点击付费，成本直控"
    }, {
        img: 'itemBg11',
        text_title: "动态搜索",
        text_intro: "基于网站内容，自动匹配相关搜索字词"
    }, {
        img: 'itemBg12',
        text_title: "产品广告",
        text_intro: "搜索结果页直呈产品优质图文，吸纳视线流量变现"
    },
]
const QueueFour = [
    {
        img: 'itemBg13',
        text_title: "搜索广告",
        text_intro: "基于关键词，显眼位置结果页优先展示"
    }, {
        img: 'itemBg14',
        text_title: "信息流广告",
        text_intro: "推广信息自然融入百度平台资讯流，穿插展现"
    }, {
        img: 'itemBg15',
        text_title: "品牌广告",
        text_intro: "打造专业品牌形象，多元广告形式提升效能"
    }, {
        img: 'itemBg16',
        text_title: "开屏广告",
        text_intro: "APP开屏，更多流量，更多曝光"
    }, {
        img: 'itemBg17',
        text_title: "聚屏广告",
        text_intro: "程序化数字屏幕广告平台，生活场景时刻覆盖"
    },
]
const QueueFive = [
    {
        img: 'itemBg18',
        text_title: "APP开屏广告",
        text_intro: "日均1千万+曝光率，强化品牌展示"
    }, {
        img: 'itemBg19',
        text_title: "图片广告",
        text_intro: "图片尺寸/组合自由选择，信息流中展露优品"
    }, {
        img: 'itemBg20',
        text_title: "视频广告",
        text_intro: "灵活全面创意多，可按CPC点击计费"
    }, {
        img: 'itemBg21',
        text_title: "详情页广告",
        text_intro: "资讯全文末端要位图文露出，点击即可跳转广告页面"
    },
]
const QueueSix = [
    {
        img: 'itemBg22',
        text_title: "微信广告",
        text_intro: "微信广告是出现在微信朋友圈、公众号和小程序中，以一种更加贴近用户生活方式呈现的广告形式"
    }, {
        img: 'itemBg23',
        text_title: "QQ广告",
        text_intro: "国内第一年轻社交平台，基于海量用户社交关系，用黑科技和多元玩法赋能品牌在社交、运动、购物、游戏等场景中与年轻用户深度互动"
    }, {
        img: 'itemBg24',
        text_title: "腾讯视频广告",
        text_intro: "中国领先的在线视频媒体平台，月活跃用户5亿+。广告以原生形式出现在娱乐化流量场景中，智能触达用户，影响用户对品牌的感知"
    }, {
        img: 'itemBg25',
        text_title: "腾讯新闻广告",
        text_intro: "业界领先的新闻资讯平台，月活跃用户2.9亿+。广告原生出现于资讯信息流中，依用户属性、历史浏览行为、兴趣偏好等智能投放"
    }, {
        img: 'itemBg26',
        text_title: "腾讯看点广告",
        text_intro: "腾讯看点立足于腾讯社交生态优势，以腾讯QQ·看点、QQ浏览器·看点和看点快报APP三端为核心，形成“社交×资讯”全场景打通的产品矩阵"
    }, {
        img: 'itemBg27',
        text_title: "优量广告",
        text_intro: "基于腾讯广告生态体系，依托于腾讯广告平台技术，在合作媒体上展示的广告产品。汇集10万+APP，月覆盖用户超过10亿"
    },
]

const OtherData = [{
    img: "widerBg1",
    intros: ['覆盖全球28亿用户，探寻商机的一流推广宝库',
        '一经开启，在Facebook动态信息、桌面版右边栏、Audience Network、Instagram广告均可展示，信息触达面极为广泛',
        '基于真实用户数据的极精准目标定位，大数据直接匹配潜在高兴趣度顾客'
    ]
}, {
    img: "widerBg2",
    intros: ['搜索引擎头号巨头，相关商品搜索即触发广告传达，直击高需求目标受众痒点',
        '预算精确可控，广告点击/展示/互动付费方案自选，毫不费力，获得更个性化的高转化率',
        '智能广告助力中小企业稳步推广，机器学习针对目标量身打造、优化'
    ]
}, {
    img: "widerBg3",
    intros: ['全球第二大搜索引擎，150亿月度搜索次数，渠道海外用户点击触达',
        '按效果付费，预算上限可设，广告产出比大幅提升',
        '设备、地域、时间均可定位，真实目标用户快捷链接'
    ]
}, {
    img: "widerBg4",
    intros: ['每日超60亿次搜索，超1亿用户浏览信息流、800亿次定位请求，生活场景全盘覆盖',
        'AI技术简化投放，洞察用户行为偏好，每一次投放都有的放矢',
        '广告费用灵活调整，基于目标优化成本'
    ]
}, {
    img: "widerBg5",
    intros: ['月活跃用户1.75亿，每人日均使用76分钟，大流量蕴含大商机',
        '依托用户兴趣，智能推荐个性化新闻，内容即推广',
        '实时查看数据，移动广告页面编辑器自动适配手机'
    ]
}, {
    img: "widerBg6",
    intros: ['多款海量流量产品，场景全覆盖，时时能触达',
        '多维智能定向，标签画像，更高转化自动匹配',
        '算法引擎加持，全链路环节解决方案'
    ]
}]

const ServiceData = [
    {
        title: "开通账户",
        details: ["精编指南", "顾问沟通"]
    }, {
        title: "定位受众",

        details: ["工具支持", "粘性巩固", "营销漏斗"]
    }, {
        title: "创建广告",
        details: ["素材组合", "创意模板", "投放布局"]
    }, {
        title: "效果评估",
        details: ["数据分析", "策略调整", "内容优化"]
    },
]


export default function MarketingForce(params: Ipage) {
    return <>
        <div className={styles.marketingForce}>
            <Helmet title='营销力量-Zero Miracle' />

            {/* Facebook专营团队 */}
            <div id="facebook" />
            <Title>Facebook专营团队</Title>
            <ChromeIntro img={getImg(OtherData[0].img)} intros={OtherData[0].intros} />
            <div style={{ textAlign: "center", color: "#183B56", fontSize: "24px", lineHeight: "32px", marginTop: "50px" }}>主要广告类型</div>
            <FlexBox>
                {QueueOne.map(item => <RenderItem img={getImg(item.img)} text_intro={item.text_intro} text_title={item.text_title} key={item.text_intro} />)}
            </FlexBox>

            {/* Zero Miracle全流程Facebook广告营销赋能服务 */}

            <div className={styles.service}>
                <Row>
                    <Col md={24} sm-={0} xs={0}>
                        <div className={styles.service_title}>Zero Miracle全流程Facebook广告营销赋能服务</div>
                    </Col>
                    <Col md={0} sm={24} xs={24}>
                        <div className={styles.service_title}>
                            Zero Miracle全流程
                            <br />
                            Facebook广告营销赋能服务
                        </div>
                    </Col>
                </Row>

                <ul className={styles.flex_group}>
                    {
                        ServiceData.map(item => <li className={styles.flex_item} key={item.title}>
                            <div className={styles.text_big}>{item.title}</div>
                            <div className={styles.text_normal}>
                                {item.details.map(item => <div key={item}>{item}</div>)}
                            </div>
                        </li>)
                    }
                </ul>
            </div>


            {/* Google、Bing投放团队 */}
            <div id="google" />
            <div style={{ background: "#F9FBFE", borderTop: "1px solid transparent" }}>
                <Title>Google、Bing投放团队</Title>
                <ChromeIntro img={getImg(OtherData[1].img)} intros={OtherData[1].intros} />
                <FlexBox>
                    {QueueTwo.map(item => <RenderItem img={getImg(item.img)} text_intro={item.text_intro} text_title={item.text_title} key={item.text_intro} />)}
                </FlexBox>
                <Line />
                <ChromeIntro img={getImg(OtherData[2].img)} intros={OtherData[2].intros} />
                <FlexBox>
                    {QueueThree.map(item => <RenderItem img={getImg(item.img)} text_intro={item.text_intro} text_title={item.text_title} key={item.text_intro} />)}
                </FlexBox>
            </div>


            {/* 百度、头条、腾讯投放团队 */}
            <div id="baidu" />
            <Title>百度、头条、腾讯投放团队</Title>
            <ChromeIntro img={getImg(OtherData[3].img)} intros={OtherData[3].intros} />
            <FlexBox>
                {QueueFour.map(item => <RenderItem img={getImg(item.img)} text_intro={item.text_intro} text_title={item.text_title} key={item.text_intro} />)}
            </FlexBox>
            <Line />
            <ChromeIntro img={getImg(OtherData[4].img)} intros={OtherData[4].intros} />
            <FlexBox>
                {QueueFive.map(item => <RenderItem img={getImg(item.img)} text_intro={item.text_intro} text_title={item.text_title} key={item.text_intro} />)}
            </FlexBox>
            <Line />
            <ChromeIntro img={getImg(OtherData[5].img)} intros={OtherData[5].intros} />
            <FlexBox>
                {QueueSix.map(item => <RenderItem img={getImg(item.img)} text_intro={item.text_intro} text_title={item.text_title} key={item.text_intro} />)}
            </FlexBox>
            <Row>
                <Col md={24} sm={0} xs={0}>
                    <img src={getImg('banner')} alt="footerbanner" style={{ marginTop: "120px", width: "100%" }} />
                </Col>
                <Col md={0} sm={24} xs={24}>
                    <img src={getImg('banner_mobile')} alt="footerbanner" style={{ marginTop: "40px", width: "100%" }} />
                </Col>
            </Row>
        </div>
    </>
}